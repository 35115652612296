import React from "react";
import { Header } from "./components/header/header";
import { UserContext } from "./UserContext";
import { HomeCarousel } from "./components/carousel/carousel";
import { Location } from "./components/location/location";
import { Inquiry } from "./components/inquiry/inquiry";
import { Content } from "./components/content/content";
import { ToastContainer } from "react-toastify";
import { Amenities } from "./components/amenities/amenities";
import { FloatingMenu } from "./components/floatingMenu/floatingMenu";

function App() {
  const [language, setLanguage] = React.useState<boolean>(true);

  return (
    <UserContext.Provider
      value={{ isEnglish: language, updateLanguage: setLanguage }}
    >
      <Header />
      {/* <VideoBackground /> */}
      <HomeCarousel />
      <Content />
      <div className="formWrapper">
        <Amenities />
        <Inquiry />
        <div style={{ padding: "0 30px" }}>
          <img className="affiliateImage" src={require(`./assets/agroTourismCertificate.png`)} alt="Goverment affiliated agro tourism centre" />
        </div>
        <Location />
        <ToastContainer />
        <FloatingMenu />
      </div>
    </UserContext.Provider>
  );
}

export default App;

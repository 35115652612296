const CarouselImages = [
  {
    id: 1,
    src: require(`../../assets/FarmImages/PXL_20230513_015722571.jpg`),
    alt: "Image 1",
  },
  {
    id: 2,
    src: require(`../../assets/FarmImages/PXL_20230513_015727048.jpg`),
    alt: "Image 2 ",
  },
  {
    id: 3,
    src: require(`../../assets/FarmImages/PXL_20230513_020006601.jpg`),
    alt: "Image 3",
  },
  {
    id: 4,
    src: require(`../../assets/FarmImages/PXL_20230513_020010176.jpg`),
    alt: "Image 4",
  },
  {
    id: 5,
    src: require(`../../assets/FarmImages/PXL_20230513_020023479.jpg`),
    alt: "Image 5",
  },
];
export default CarouselImages;

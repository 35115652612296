import * as React from "react";
import "./style.css";
import { UserContext } from "../../UserContext";

export const Location: React.FC = (): JSX.Element => {
  const { isEnglish } = React.useContext(UserContext);

  const locationLabel = isEnglish ? "Location" : "स्थान";
  return (
    <div className="locationContainer">
      <a
        href="https://www.google.com/maps/dir//Alchin+Farm+and+Agro+Tourism,+Khasara+no.+2%2F1+%26+2%2F4,+Mouja,+Shivni+(Rithi),+Dongargaon,+Khapri,+Road,+Taluka,+Kuhi,+Maharashtra+441202/@21.0037565,79.2272996,15.54z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3bd4b1f333f718e9:0x3a8e5825e1bbaa8a!2m2!1d79.2272766!2d21.0032571!3e0?entry=ttu"
        target="_blank"
        rel="noreferrer"
        aria-label="Get directions to the location"
        title="Get directions to the location"
      >
        <a href='https://maps.apple.com/maps/dir///@21.0037565,79.2272996,15z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3bd4b1f333f718e9:0x3a8e5825e1bbaa8a!2m2!1d79.2272766!2d21.0032571!3e0?entry=ttu'>
          <svg
            height="24"
            version="1.1"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(0 -1028.4)">
              <path
                d="m12.031 1030.4c-3.8657 0-6.9998 3.1-6.9998 7 0 1.3 0.4017 2.6 1.0938 3.7 0.0334 0.1 0.059 0.1 0.0938 0.2l4.3432 8c0.204 0.6 0.782 1.1 1.438 1.1s1.202-0.5 1.406-1.1l4.844-8.7c0.499-1 0.781-2.1 0.781-3.2 0-3.9-3.134-7-7-7zm-0.031 3.9c1.933 0 3.5 1.6 3.5 3.5 0 2-1.567 3.5-3.5 3.5s-3.5-1.5-3.5-3.5c0-1.9 1.567-3.5 3.5-3.5z"
                fill="#c0392b"
              />
              <path
                d="m12.031 1.0312c-3.8657 0-6.9998 3.134-6.9998 7 0 1.383 0.4017 2.6648 1.0938 3.7498 0.0334 0.053 0.059 0.105 0.0938 0.157l4.3432 8.062c0.204 0.586 0.782 1.031 1.438 1.031s1.202-0.445 1.406-1.031l4.844-8.75c0.499-0.963 0.781-2.06 0.781-3.2188 0-3.866-3.134-7-7-7zm-0.031 3.9688c1.933 0 3.5 1.567 3.5 3.5s-1.567 3.5-3.5 3.5-3.5-1.567-3.5-3.5 1.567-3.5 3.5-3.5z"
                fill="#e74c3c"
                transform="translate(0 1028.4)"
              />
            </g>
          </svg>
          <b>{locationLabel}</b>
        </a>
      </a>
      <br />
      <p>
        Khasara no. 2/1 & 2/4, Mouja, Shivni (Rithi) Dongargaon, Khapri Road,
        Taluka Kuhi District - Nagpur Pincode - 441202 Landmark - Backside of
        Pavan Bar & Restraurant
      </p>
      <br />
      <div>
        <iframe title="map" className="location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14898.940007259513!2d79.2272766!3d21.0032571!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4b1f333f718e9%3A0x3a8e5825e1bbaa8a!2sAlchin%20Farm%20and%20Agro%20Tourism!5e0!3m2!1sen!2sin!4v1692772596218!5m2!1sen!2sin" width="600" height="450" style={{"border":0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
};

import React from "react";
import { UserContext } from "../../UserContext";

export const Content: React.FC = (): JSX.Element => {
  const { isEnglish } = React.useContext(UserContext);

  const title = isEnglish
    ? "Welcome to Alchin Farms & Agro-Tourism Centre - Nagpur"
    : "अलचिन फार्म आणि कृषी-पर्यटन केंद्र - नागपूर मध्ये आपले स्वागत आहे";
  const para1 = isEnglish
    ? `Escape the hustle and bustle of the city and immerse yourself in the
  serene beauty of nature at our charming farmhouse nestled in the
  picturesque woods. Located just a short drive from the city outskirts,
  Alchin Farm offers a perfect retreat for those seeking tranquility and
  rejuvenation.`
    : "शहराच्या गजबजाटातून बाहेर पडा आणि नयनरम्य जंगलात वसलेल्या आमच्या मोहक फार्महाऊसमध्ये निसर्गाच्या निर्मळ सौंदर्यात मग्न व्हा. शहराच्या हद्दीपासून थोड्याच अंतरावर, अल्चिन फार्म शांतता आणि कायाकल्प शोधणार्‍यांसाठी एक उत्तम माघार देते.";
  const para2 = isEnglish
    ? `Accommodation: Our farmhouse provides comfortable and cozy rooms
  designed to provide a peaceful stay. Each room is tastefully decorated
  with rustic charm and offers breathtaking views of the surrounding
  forest. Whether you're traveling solo, with a partner, or a group, we
  have the perfect accommodation option to suit your needs.`
    : "राहण्याची सोय: आमचे फार्महाऊस शांततापूर्ण मुक्काम देण्यासाठी डिझाइन केलेल्या आरामदायक आणि आरामदायक खोल्या प्रदान करते. प्रत्येक खोली चवदारपणे अडाणी मोहिनीने सजलेली आहे आणि आसपासच्या जंगलाची चित्तथरारक दृश्ये देते. तुम्ही एकट्याने प्रवास करत असाल, जोडीदारासोबत किंवा एखाद्या गटासह, आमच्याकडे तुमच्या गरजेनुसार निवासाचा उत्तम पर्याय आहे.";

  return (
    <div style={{ padding: "50px 30px 10px 30px" }}>
      <h1 style={{ textAlign: "center" }}>{title}</h1>
      <p>{para1}</p>
      <br />
      <p>{para2}</p>
    </div>
  );
};

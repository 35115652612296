import * as React from "react";
import { Calendar, DayRange, utils } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "./style.css";
import { UserContext } from "../../UserContext";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Inquiry: React.FC = (): JSX.Element => {
  emailjs.init("jfwXXFDJsQMJ34ytP");
  const defaultTime = new Date();

  const [selectedDayRange, setSelectedDayRange] = React.useState<DayRange>({
    from: {
      day: defaultTime.getDate(),
      month: defaultTime.getMonth() + 1,
      year: defaultTime.getFullYear(),
    },
    to: {
      day: defaultTime.getDate() + 1,
      month: defaultTime.getMonth() + 1,
      year: defaultTime.getFullYear(),
    },
  });

  const { isEnglish } = React.useContext(UserContext);

  const dateLabel = isEnglish ? "Select Dates" : "तारखा निवडा";
  const nameLabel = isEnglish ? "Name" : "नाव";
  const contactLabel = isEnglish ? "Contact Number" : "संपर्क क्रमांक";
  const guestLabel = isEnglish ? "Guests" : "पाहुणे";
  const inquiryBtnText = isEnglish ? "Send Inquiry" : "चौकशी पाठवा";
  const nameValidationText = isEnglish
    ? "* Please enter a valid name"
    : "* कृपया एक वैध नाव प्रविष्ट करा";
  const mobileValidationText = isEnglish
    ? "* Please enter a valid number"
    : "* कृपया वैध क्रमांक प्रविष्ट करा";

  const [isNumberValid, setNumberValidation] = React.useState(true);
  const [isCustomerNameValid, setCustomerNameValidation] = React.useState(true);

  const [guestCount, updateGuestCount] = React.useState(2);
  const [number, setNumber] = React.useState("");
  const [name, setName] = React.useState("");

  const templateId = "template_npoaens";
  const variables: {
    from_date: string;
    to_date: string;
    guest_count: number;
    contact_number: string;
    customer_name: string;
  } = {
    from_date:
      selectedDayRange.from !== null && selectedDayRange.from !== undefined
        ? `${selectedDayRange.from.day}-${selectedDayRange.from.month}-${selectedDayRange.from.year}`
        : "",
    to_date:
      selectedDayRange.to !== null && selectedDayRange.to !== undefined
        ? `${selectedDayRange.to.day}-${selectedDayRange.to.month}-${selectedDayRange.to.year}`
        : "",
    guest_count: guestCount,
    contact_number: number,
    customer_name: name,
  };

  const showToast = () => {
    toast.success("Inquiry Sent Successfully!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const isMobileNumberValid = (number: string): boolean => {
    if (/^[6-9]\d{9}$/.test(number)) {
      return true;
    } else {
      setNumberValidation(false);
      return false;
    }
  };

  const isNameValid = (name: string): boolean => {
    if (name.length > 3 && /^[ A-Za-z]+$/.test(name)){
      setCustomerNameValidation(true);
      return true;}
    else {
      setCustomerNameValidation(false);
      return false;
    }
  };

  const noValidationError=(): boolean =>{

    let isValid: boolean = false;

    isValid = isNameValid(name);

    isValid = isMobileNumberValid(number) && isValid;

    return isValid;
  }

  const sendEmail = () => {
    if (noValidationError()) {
      emailjs
        .send("service_lm2kchn", templateId, variables)
        .then((res) => {
          showToast();
        })
        // Handle errors here however you like, or use a React error boundary
        .catch((err) =>
          console.error(
            "Some Issue occured while sending email, please try again later",
            err
          )
        );
    }
  };

  return (
    <div className="inquiryForm">
      <p>
        <label>
          <svg
            version="1.0"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            width="201px"
            height="201px"
            viewBox="0 0 64 64"
            enable-background="new 0 0 64 64"
            fill="#ffffff"
            stroke="#ffffff"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <g>
                {" "}
                <path
                  fill="#ffffff"
                  d="M11,54h6c0.553,0,1-0.447,1-1v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5C10,53.553,10.447,54,11,54 z M12,49h4v3h-4V49z"
                ></path>{" "}
                <path
                  fill="#ffffff"
                  d="M23,54h6c0.553,0,1-0.447,1-1v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5C22,53.553,22.447,54,23,54 z M24,49h4v3h-4V49z"
                ></path>{" "}
                <path
                  fill="#ffffff"
                  d="M35,54h6c0.553,0,1-0.447,1-1v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5C34,53.553,34.447,54,35,54 z M36,49h4v3h-4V49z"
                ></path>{" "}
                <path
                  fill="#ffffff"
                  d="M11,43h6c0.553,0,1-0.447,1-1v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5C10,42.553,10.447,43,11,43 z M12,38h4v3h-4V38z"
                ></path>{" "}
                <path
                  fill="#ffffff"
                  d="M23,43h6c0.553,0,1-0.447,1-1v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5C22,42.553,22.447,43,23,43 z M24,38h4v3h-4V38z"
                ></path>{" "}
                <path
                  fill="#ffffff"
                  d="M35,43h6c0.553,0,1-0.447,1-1v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5C34,42.553,34.447,43,35,43 z M36,38h4v3h-4V38z"
                ></path>{" "}
                <path
                  fill="#ffffff"
                  d="M47,43h6c0.553,0,1-0.447,1-1v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5C46,42.553,46.447,43,47,43 z M48,38h4v3h-4V38z"
                ></path>{" "}
                <path
                  fill="#ffffff"
                  d="M11,32h6c0.553,0,1-0.447,1-1v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5C10,31.553,10.447,32,11,32 z M12,27h4v3h-4V27z"
                ></path>{" "}
                <path
                  fill="#ffffff"
                  d="M23,32h6c0.553,0,1-0.447,1-1v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5C22,31.553,22.447,32,23,32 z M24,27h4v3h-4V27z"
                ></path>{" "}
                <path
                  fill="#ffffff"
                  d="M35,32h6c0.553,0,1-0.447,1-1v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5C34,31.553,34.447,32,35,32 z M36,27h4v3h-4V27z"
                ></path>{" "}
                <path
                  fill="#ffffff"
                  d="M47,32h6c0.553,0,1-0.447,1-1v-5c0-0.553-0.447-1-1-1h-6c-0.553,0-1,0.447-1,1v5C46,31.553,46.447,32,47,32 z M48,27h4v3h-4V27z"
                ></path>{" "}
                <path
                  fill="#ffffff"
                  d="M60,4h-7V3c0-1.657-1.343-3-3-3s-3,1.343-3,3v1H17V3c0-1.657-1.343-3-3-3s-3,1.343-3,3v1H4 C1.789,4,0,5.789,0,8v52c0,2.211,1.789,4,4,4h56c2.211,0,4-1.789,4-4V8C64,5.789,62.211,4,60,4z M49,3c0-0.553,0.447-1,1-1 s1,0.447,1,1v3v4c0,0.553-0.447,1-1,1s-1-0.447-1-1V6V3z M13,3c0-0.553,0.447-1,1-1s1,0.447,1,1v3v4c0,0.553-0.447,1-1,1 s-1-0.447-1-1V6V3z M62,60c0,1.104-0.896,2-2,2H4c-1.104,0-2-0.896-2-2V17h60V60z M62,15H2V8c0-1.104,0.896-2,2-2h7v4 c0,1.657,1.343,3,3,3s3-1.343,3-3V6h30v4c0,1.657,1.343,3,3,3s3-1.343,3-3V6h7c1.104,0,2,0.896,2,2V15z"
                ></path>{" "}
              </g>{" "}
            </g>
          </svg>
          {dateLabel}
        </label>
        <Calendar
          value={selectedDayRange}
          onChange={(dayRange) => setSelectedDayRange(dayRange)}
          shouldHighlightWeekends
          minimumDate={utils("en").getToday()}
        />
      </p>
      <p>
        <label>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#ffffff"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <circle
                cx="12"
                cy="6"
                r="4"
                stroke="#ffffff"
                stroke-width="1.5"
              ></circle>{" "}
              <path
                d="M20 17.5C20 19.9853 20 22 12 22C4 22 4 19.9853 4 17.5C4 15.0147 7.58172 13 12 13C16.4183 13 20 15.0147 20 17.5Z"
                stroke="#ffffff"
                stroke-width="1.5"
              ></path>{" "}
            </g>
          </svg>
          {nameLabel}
        </label>
        {!isCustomerNameValid && (
          <label htmlFor="mobileNumber" className="mobValidation">
            {nameValidationText}
          </label>
        )}
        <div className="telContainer">
          <input
            id="name"
            type="text"
            className="telNumber"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </p>
      <p>
        <label>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                stroke="#ffffff"
                stroke-width="1.5"
                stroke-miterlimit="10"
              ></path>{" "}
            </g>
          </svg>
          {contactLabel}
        </label>
        {!isNumberValid && (
          <label htmlFor="mobileNumber" className="mobValidation">
            {mobileValidationText}
          </label>
        )}
        <div className="telContainer">
          <input
            value="+91"
            type="text"
            id="mobileNumber"
            className="telCode"
          />
          <input
            type="tel"
            maxLength={10}
            value={number}
            className="telNumber"
            onChange={(e) => setNumber(e.target.value)}
            onBlur={(e) =>
              setNumberValidation(/^[6-9]\d{9}$/.test(e.target.value))
            }
          />
        </div>
      </p>
      <p className="guestBlock">
        <label>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M19 15C21.2091 15 23 16.7909 23 19V21H21M16 10.874C17.7252 10.4299 19 8.86383 19 6.99999C19 5.13615 17.7252 3.57005 16 3.12601M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7ZM5 15H13C15.2091 15 17 16.7909 17 19V21H1V19C1 16.7909 2.79086 15 5 15Z"
                stroke="#ffffff"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
            </g>
          </svg>{" "}
          {guestLabel}
        </label>
        <div className="guestContainer">
          <button
            className="guests"
            onClick={() => {
              if (guestCount - 1 > 0) updateGuestCount(guestCount - 1);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <title></title>{" "}
                <g id="Complete">
                  {" "}
                  <g id="minus">
                    {" "}
                    <line
                      fill="none"
                      stroke="#000000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      x1="4"
                      x2="20"
                      y1="12"
                      y2="12"
                    ></line>{" "}
                  </g>{" "}
                </g>{" "}
              </g>
            </svg>
          </button>

          <label>{guestCount}</label>
          <button
            className="guests"
            onClick={() => updateGuestCount(guestCount + 1)}
          >
            <svg
              fill="#000000"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path d="M9,17h6v6a1,1,0,0,0,2,0V17h6a1,1,0,0,0,0-2H17V9a1,1,0,0,0-2,0v6H9a1,1,0,0,0,0,2Z"></path>{" "}
              </g>
            </svg>
          </button>
        </div>
      </p>
      <p>
        <button className="sendInquiryBtn" onClick={sendEmail}>
          {inquiryBtnText}
        </button>
      </p>
    </div>
  );
};

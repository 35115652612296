import React from "react";
import "./style.css";
import Slider, { Settings } from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselImages from "./carouselImages";

export const HomeCarousel: React.FC = (): JSX.Element => {
  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    adaptiveHeight: true,
    centerMode: true,
    arrows: false,
    cssEase: "linear",
    variableWidth: true,
  };

  return (
    <div className="carouselContainer">
      <Slider {...settings}>
        {CarouselImages.map((item) => (
          <div key={item.id}>
            <img src={item.src} alt={item.alt} className="carouselImage" />
          </div>
        ))}
      </Slider>
    </div>
  );
};
